<template>
  <v-footer
    id="home-footer"
    color="grey darken-4"
    dark
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col
          class="text-center text-md-right"
          cols="12"
          md="6"
        >
          © 2022, ООО «ИЛ Кооператив»
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="show_snack" color="primary" timeout="5000" app centered>
      {{ snack_message }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="show_snack = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-footer>
</template>

<script>
  import EventBus from '@/mixins/event-bus'

  export default {
    name: 'HomeFooter',

    mounted () {
      var self = this
      EventBus.$on('show-snack', function (message) {
        self.snack_message = message
        self.show_snack = true        
      })     
    },

    data: () => ({
      snack_message: "",
      show_snack: false,
    }),
  }
</script>

<style lang="sass">  
  #home-footer a
    text-decoration: none
  
  .v-snack__content
    font-size: 1.2rem !important
    line-height: 1.7rem !important
</style>
